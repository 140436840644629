// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-tsx": () => import("./../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-products-listing-tsx": () => import("./../src/templates/productsListing.tsx" /* webpackChunkName: "component---src-templates-products-listing-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-redirect-page-template-tsx": () => import("./../src/templates/redirectPageTemplate.tsx" /* webpackChunkName: "component---src-templates-redirect-page-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../src/templates/blogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../src/templates/productTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

